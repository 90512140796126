<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col class="d-flex align-items-center mb-md-0">
        <h1 class="font-weight-bold text-primary">Postcode Report</h1>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading && single && single.length">
      <b-col>
        <b-list-group class="mb-3">
          <b-list-group-item variant="dark">
            <div class="d-flex flex-row">
              <p class="font-weight-bold flex-fill mb-0">Postcode</p>
              <p class="font-weight-bold mb-0">Order Count</p>
            </div>
          </b-list-group-item>
          <b-list-group-item v-for="(item, itemIndex) in single" :key="`item${itemIndex}`">
            <div class="d-flex flex-row">
              <p class="flex-fill mb-0">{{ item.billing_postcode }}</p>
              <p class="mb-0">{{ item.postcode_total }}</p>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading && !single.length">
      <b-col class="text-center" cols="12">
        <b-card class="py-5 text-center">
          <p class="mb-4"><b-icon class="display-1 text-dark" icon="cloud-arrow-down"></b-icon></p>
          <p class="font-weight-normal h5 mb-0 text-dark">Could not find any results that match the given criteria.</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col class="d-flex flex-fill justify-content-center">
        <b-spinner variant="primary"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchPostcodeReport()
  },
  computed: {
    ...mapGetters('reports', ['single', 'isLoading'])
  },
  methods: {
    ...mapActions('reports', ['fetchPostcodeReport']),
  }
}
</script>

<style>

</style>
